    .character-nav {
        margin-top: 0;
        &__item {
            padding: 16px 4px;

            @media (min-width: $s) {
                padding: 32px 64px 32px;
            }

            .component {
                &__image {
                    position: relative;
                    z-index: 1;

                    a {
                        max-width: 90%;
                        transition: $animation-time all;
                        position: relative;

                        &:after {
                            content: '';
                            position: absolute;
                            top: 0;
                            left: 0;
                            bottom: 0;
                            right: 0;
                            z-index: -1;
                            border-radius: 50%;
                            transition: all $animation-time;
                        }

                        &:hover,
                        &:active {
                            &:after {
                                top: -10px;
                                left: -10px;
                                bottom: -10px;
                                right: -10px;
                                background-color: #AE3189;
                                border-radius: 50%
                            }
                        }

                        @media (min-width: $s) {
                            max-width: 175px;
                        }
                    }
                }

                &__link {
                    a {
                        @extend %primary-font;
                        font-size: 20px;
                        line-height: 22px;
                        color: $white;
                        border: 8px solid transparent;
                        border-radius: 10px;
                        padding: 16px;
                        transition: $animation-time all;
                        margin-top: -4px;
                        text-transform: capitalize;
                        @media (min-width: $s) {
                            min-width: 260px;
                            font-size: 26px;
                            line-height: 26px;
                        }
                        &:hover,
                        &:active {
                            background: #AE3189;
                            border-color: $white;
                        }
                    }
                }
            }

            &.is-active {
                .component {
                    &__image {
                        a {
                            &:after {
                                top: -10px;
                                left: -10px;
                                bottom: -10px;
                                right: -10px;
                                background-color: #AE3189;
                                border-radius: 50%
                            }
                        }
                    }

                    &__link {
                        a {
                            background: #AE3189;
                            border-color: $white;
                        }
                    }
                }
            }

            @media (min-width: $s) {
                &:first-of-type {
                    .component {
                        &__image {
                            justify-content: flex-end;

                            a {
                                margin-right: 38px;
                            }
                        }

                        &__link {
                            justify-content: flex-end;
                        }
                    }
                }

                &:last-of-type {
                    .component {
                        &__image {
                            justify-content: flex-start;

                            a {
                                margin-left: 38px;
                            }
                        }

                        &__link {
                            justify-content: flex-start;
                        }
                    }
                }
            }
        }
    }