.characters {
    .character {
        &__info {
            border-bottom: 1px solid $sky-blue;
            padding-bottom: 32px;
            h2 {
                color: $dark-pink;
                text-align: center;
                margin-bottom: 16px;
                @media (min-width: $s) {
                    margin-bottom: 48px;
                    text-align: left;
                }
            }
            p {
                margin-bottom: 32px;
                color: $black
            }
        }
        &__related-books {
            border-bottom: 1px solid $sky-blue;
            .books-carousel {
                .books-container {
                    align-items: center;
                    @media (min-width: $s) {
                        align-items: flex-end;
                    }
                }
                .tns-controls {
                    left: -10px;
                    right: -10px;
                }
                .book-card {
                    padding: 10px 16px;
                    &__copy {
                        display: none;
                    }
                }
            }
        }
    }
}