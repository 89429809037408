.footer {
    padding-top: 48px;
    p, a {
        font-size: 15px;
    }
    @media (min-width: $s) {
        padding-top: 64px;
    }
    &__nav {
        margin-bottom: 1em;
    }

    &-container {
        max-width: $m;
        margin: 0 auto;

        h2 {
            @extend %primary-font;
            color: $mid-pink;
            font-size: 40px;
            line-height: 64px;
            margin-bottom: 0;
            margin-bottom: 32px;
        }
    }

    * {
        @extend %secondary-font;
        color: $black;
    }

    .social-medias {
        display: flex;
        justify-content: center;

        a {
            display: inline-block;
            width: 62px;
            height: 62px;
            display: flex;
            align-items: center;
            flex-flow: column nowrap;
            justify-content: center;
            border-radius: 50%;
            background: $mid-pink;
            margin: 0 10px;
            transition: $animation-time all;
            &:hover {
                background: $pink;
            }
            @media (min-width: $s) {
                width: 106px;
                height: 106px;
            }
            span {
                display: flex;
                align-items: center;
                &:before {
                    color: $white;
                    font-size: 34px;
                    @media (min-width: $s) {
                        font-size: 58px;
                    }
                }
            }
        }
    }
}