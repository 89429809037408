.tv-series {
    .header {
        border-bottom: 1px solid $pale-pink;
    }
    &__embed-section {
        .primary-button {
            font-size: 22px;
            line-height: 48px;
        }
    }
}