.activity-detail {
    .asset-detail {
        align-items: flex-start;
        @media (max-width: $s) {
            text-align: center;
            align-items: center;
        }
        &__image {
            img {
                max-width: 90%;
                margin: 0 auto;
                @media (max-width: $s) {
                    max-width: 75%;
                }
            }
        }
        &__info {
            justify-content: flex-start;
        }
        &__category {
            color: $light-purple;
            margin: 8px 0;
            font-size: 22px;
            line-height: 28px;
            text-transform: uppercase;
            @media (max-width: $s) {
                text-align: center;
            }
        }
        &__title {
            margin-bottom: 8px;
            color: $dark-pink;
            text-align: center;
            @media (min-width: $s) {
                text-align: left;
            }
        }
        &__description {
            color: $pink;
            font-size: 22px;
            line-height: 28px;
            margin-bottom: 64px;
            @media (max-width: $s) {
                text-align: center;
                padding: 0 16px;
                margin-bottom: 32px;
            }
        }
        &__action {
            @include siteButton($dark-pink, $white);
            font-size: 24px;
            text-transform: uppercase;
            padding: 20px 48px 18px;
            display: inline-block;
            &:hover {
                background: $mid-pink;
                color: $white;
            }
        }
        &__carousel {
            &:after {
                z-index: 1;
            }
            .assets-carousel {
                &__book-title {
                    color: $light-purple;
                    font-size: 22px;
                    line-height: 28px;
                }
            }
        }
        &__related-title {
            color: $pink;
            padding: 24px 0 32px;
            font-size: 40px;
            line-height: 48px;
            @media (max-width: $s) {
                margin: 0;
                padding: 24px 0;
            }
        }
        &__related-subtitle {
            display: none;
        }
        .related-book {
            align-items: flex-start;
            @media (max-width: $s) {
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
            h4 {
                text-transform: uppercase;
                color: $dark-purple;
                font-size: 30px;
                line-height: 38px;
                margin-bottom: 8px;
                @media (max-width: $s) {
                    flex-direction: column;
                    justify-content: center;
                }
            }
            &__price {
                margin-bottom: 0;
            }
            &__price, &__summary {
                color: $dark-purple;
                font-size: 18px;
                line-height: 22px;
                @media (min-width: $s) {
                    font-size: 22px;
                    line-height: 28px;
                }
            }
            &__content {
                @media (max-width: $s) {
                    width: 100%;
                    padding: 16px;
                    text-align: center;
                    margin-top: 16px;
                }
            }
        }
    }
}
