/*!
Description:  Fancy Nancy Style
Author: Supadu
Author URI:   https://www.supadu.com
Template:     fancy-nancy
*/

@import "./src/common/scss/style.scss";
@import "base/base";
@import "components/components";
@import "views/views";