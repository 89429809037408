.main-navigation {
    background-color: $mid-pink;
    @media (min-width: $s) {
        background-color: $nav-background-colour;
        padding: 0 8px;
    }
    &.sticky-nav {
        border-bottom: 1px solid $pale-pink;
    }
    &.show-menu {
        padding-bottom: 0;
    }
    .main-menu {
        @media (max-width: 767px) {
            margin-top: 8px;
        }
        li {
            margin: 0;
            a {
                height: 100%;
                color: $white;
                display: block;
                @extend %primary-font;
                font-size: 29px;
                line-height: 29px;
                padding: 8px;
                margin: 0 -8px;
                @media (min-width: $s) {
                    margin: 0;
                    padding: 16px;
                    color: $nav-links-colour;
                }
                &:hover {
                    background-color: $dark-pink;
                    color: $white;
                    @media (min-width: $s) {
                        background-color: $nav-links-background-colour-hover;
                        color: $nav-links-colour-hover;
                    }
                }
                &.is-active {
                    background-color: $dark-pink;
                    color: $white;
                    @media (min-width: $s) {
                        background-color: $nav-current-link-background-colour;
                        color: $nav-links-colour-hover;
                    }
                }
            }
        }
    }
}