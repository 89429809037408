.books {
    .hero {
        p {
            max-width: $s;
            margin: 0 auto;
            font-size: 24px;
            line-height: 32px;
        }
    }
    .grid-subtitle {
        max-width: 850px;
        margin: 0 auto;
        p {
            font-size: 22px;
            line-height: 28px;
        }
    }
    .book-landing {
        .book-list {
            @media (min-width: $m) {
                grid-template-columns: repeat(5, minmax(0, 1fr));
            }
        }
        .book-card {
            .book-title {
                margin-top: 8px;
                a {
                    color: $light-purple;
                    font-size: 22px;
                    line-height: 28px;
                }
            }
        }
        .pagination-top, .pagination-bottom {
            margin-bottom: 0;
            @media (min-width: $s) {
                justify-content: flex-end;
            }
            .pagination {
                &__results-qty {
                    display: none;
                }
                li {
                    margin-bottom: 16px;
                }
            }
        }
    }
}