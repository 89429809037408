$white: white;
$black: #454545;
$dark-pink: #CF2F70;
$pink: #DC4C8A;
$mid-pink: #EC6AA9;
$light-pink: #E275A4;
$pale-pink: #F6C9DD;
$light-purple: #8066B2;
$dark-purple: #794F9A;
$cream: #FFDFC7;
$off-white: #FFF3FC;
$sky-blue: #D6D8FD;
$light-blue: #6DCCD2;
$dark-blue: #667CCA;
$green: #32B193;

$max-width: 1060px;
$animation-time: 0.3s;
$section-border-top: $sky-blue;
$background-body: $white;
$body-font-color: $black;
$primary-color: $dark-pink;
$secondary-color: $dark-purple;
$background-buy-section: $off-white;
$assetBackgrounds: $light-pink, $light-blue, $green, $pink, $light-purple, $dark-blue, $light-purple;

/* Header */

$header-background-color: $white;

/* NAVIGATION */

$nav-links-colour: $light-purple;
$nav-links-colour-hover: $mid-pink;
$nav-links-background-colour-hover: $white;
$nav-current-link-colour: $mid-pink;
$nav-current-link-background-colour: $white;
$nav-background-colour: $white;

/* default font sizes */

$base-font-size: 22px;
$h1-font-size: 55px;
$h2-font-size: 40px;
$h3-font-size: 30px;
$h4-font-size: 24px;
$h5-font-size: 16px;

/* breakpoints */
$m: 1024px;
$s: 768px;