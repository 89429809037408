.author-illustrator {
    background-color: $off-white;
    @include full_width_colour($off-white);
    main {
        .author-bio {
            h2 {
                color: $light-purple;
                text-align: center;
                @media (min-width: 768px) {
                    text-align: left;
                    margin-bottom: 16px;
                }
            }
            p, a {
                margin-bottom: 32px;
                color: $pink;
            }
            a {
                font-size: 22px;
                line-height: 28px;
                text-decoration: underline;
                &:hover {
                    color: $dark-pink;
                }
            }
        }
    }
}