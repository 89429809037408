.newsletter {
    h2 {
        color: #F99EC5;
        text-align: center;
        padding-bottom: 16px;
        font-size: 28px;
        @media (min-width: 768px) {
            text-align: left;
            padding: 0 32px 0 0;
            font-size: 35px;
            line-height: 39px;
        }
    }
    .sailthru-form {
        &__email {
            label {
                @extend %secondary-font;
                color: $white;
                text-transform: capitalize;
            }
        }
        &__dob {
            label {
                @extend %secondary-font;
                color: $white;
                text-transform: capitalize;
            }
            .select-style {
                border-radius: 8px;
            }
        }
        &__legal, &__restricted-age {
            @extend %secondary-font;
            color: $white;
            a {
                &:hover {
                    color: $off-white;
                }
            }
        }
        &__submit {
            button {
                background-color: $light-pink;
                color: $white;
                transition: $animation-time all;
                text-transform: uppercase;
                font-size: 24px;
                line-height: 26px;
                &:hover {
                    background: $pink;
                }
            }
        }
    }
}