@font-face {
    font-family: "Burbank-Regular, sans-serif";
    src: url('../fonts/BurbankBigRegular-Medium.otf') format('opentype'), url("../fonts/BurbankBigRegular-Medium.woff2") format("woff2"), url("../fonts/BurbankBigRegular-Medium.woff") format("woff"), url("../fonts/BurbankBigRegular-Medium.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}


%primary-font {
    font-family: "Burbank-Regular, sans-serif";
    text-transform: uppercase;
}

%secondary-font {
    font-family: "Open Sans", sans-serif;
    font-weight: normal;
}

h1,
h2,
h3,
.primary-font {
    @extend %primary-font;
}

.primary-text-colour {
    color: $primary-color;
}

h4,
h5,
body,
p,
a,
.main-font {
    @extend %primary-font;
    text-transform: none;
}

.secondary-text-colour {
    color: $secondary-color;
}

// text colour classes
.white-text-colour {
    color: $white;
}
.black-text-colour {
    color: $black;
}
.dark-pink-text-colour {
    color: $dark-pink;
}
.pink-text-colour {
    color: $pink;
}
.mid-pink-text-colour {
    color: $mid-pink;
}
.light-pink-text-colour {
    color: $light-pink;
}
.pale-pink-text-colour {
    color: $pale-pink;
}
.light-purple-text-colour {
    color: $light-purple;
}
.dark-purple-text-colour {
    color: $dark-purple;
}
.cream-text-colour {
    color: $cream;
}
.off-white-text-colour {
    color: $off-white;
}
.cream-text-colour {
    color: $cream;
}
.sky-blue-text-colour {
    color: $sky-blue;
}
.light-blue-text-colour {
    color: $light-blue;
}
.dark-blue-text-colour {
    color: $dark-blue;
}
.green-text-colour {
    color: $green;
}

h1 {
    font-size: $h1-font-size - 11;
    line-height: 52px;
    margin-bottom: 16px;
    @media (min-width: $s) {
        font-size: $h1-font-size;
        line-height: 62px;
    }
}

h2 {
    font-size: $h2-font-size - 8;
    line-height: 40px;
    margin-bottom: 8px;
    @media (min-width: $s) {
        font-size: $h2-font-size;
        line-height: 48px;
    }
}

h3 {
    font-size: $h3-font-size - 8;
    line-height: 40px;
    margin-bottom: 8px;
    margin-top: 16px;
    @media (min-width: $s) {
        font-size: $h3-font-size;
        line-height: 48px;
    }
}

h4 {
    font-size: $h4-font-size;
    line-height: $h4-font-size + 5;
    margin-bottom: 8px;
}

h5 {
    font-size: $h5-font-size;
    line-height: $h5-font-size + 5;
    margin-bottom: 8px;
}

blockquote {
    @extend %primary-font;
    font-size: 34px;
    line-height: 44px;
    @media (min-width: $s) {
        font-size: 40px;
        line-height: 53px;
    }
    cite {
        @extend %secondary-font;
        text-transform: uppercase;
        margin-top: 16px;
        display: block;
        font-size: 20px;
        line-height: 32px;
    }
}

.separator {
    padding-top: 32px;
    border-top: 3px solid $section-border-top;
}

a {
    color: $body-font-color;
    &:hover {
        color: $secondary-color;
        cursor: pointer;
    }
}

p {
    margin-bottom: 1em;
    font-size: $base-font-size;
    line-height: 28px
}

.primary-button {
    @extend %primary-font;
    @include siteButton($primary-color, $white);
    &:hover {
        background-color: $pink;
        color: $white;
    }
}

.format-button.primary-button {
    border: 2px solid $primary-color;
    color: $primary-color;
    &.active {
        color: $white;
    }
    &:hover,
    &.active {
        background-color: $primary-color;
        &:after {
            border-top-color: $primary-color;
        }
    }
}

.light-pink-button {
    @extend %primary-font;
    @include siteButton($light-pink, $white);
}

.pale-pink-button {
    @extend %secondary-font;
    @include siteButton($pale-pink, $secondary-color);
}


.button-asset--audio {
    @include siteButton($white, $black);
    &:hover {
        background-color: $white;
        color: $black;
    }
}

.back-button {
    margin: 20px 0 0 35px;
    a {
        position: relative;
        font-size: 22px;
        line-height: 28px;
        text-transform: capitalize;
        &::before{
            content: '<';
            color: $dark-pink;
            background-size: contain;
            background-repeat: no-repeat;
            position: absolute;
            display: block;
            top: 50%;
            left: -30px;
            height: 28px;
            width: 23px;
            transform: translateY(-50%);
        }
    }
}
