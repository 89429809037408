.header {
  background-color: $header-background-color;
  background-size: cover;
  &__logo {
    max-width: none;
    background-image: url('https://supadu-io.imgix.net/harpercollins-fancynancy/fancynancy-site/pink_stripes.png?auto=format');
    background-size: auto 73%;
    background-repeat: repeat-x;
    padding-bottom: 8px;
    @media (min-width: $s) {
      padding-bottom: 0;
    }
    a[href="https://www.harpercollins.com/childrens/"] {
      right: 7%;
  
      @media (max-width: $s) {
        right: 0;
      }
    }
  
    &__kids-image {
      width: 65px;
    }
  }
}
