@import "variables";
@import "typography";

body {
    background: $background-body;
}

main {
    overflow: hidden;
}

.white-background {
    background-color: $white;
    @include full_width_colour($white);
}

.off-white-background {
    background-color: $off-white;
    @include full_width_colour($off-white);
}

.dark-purple-background {
    background-color: $dark-purple;
    @include full_width_colour($dark-purple);
}

.mid-pink-background {
    background-color: $mid-pink;
    @include full_width_colour($mid-pink);
}

.pink-background {
    background-color: $pink;
    @include full_width_colour($pink);
}

.sky-blue-background {
    background-color: $sky-blue;
    @include full_width_colour($sky-blue);
}
