.home {
  .main-hero {
    h1 {
      @media(min-width: $m) {
        font-size: 60px;
        line-height: 73px;
      }
    }
    .hero {
      &__container {
        &--left {
          a {
            @include siteButton($pale-pink, $dark-pink);
            text-transform: uppercase;
            padding: 12px 32px;
            font-size: 24px;
            line-height: 26px;
          }
        }
      }
    }
  }
  .secondary-hero {
    .hero {
      .component__link {
        display: none
      }
      h1 {
        font-size: 40px;
        line-height: 48px;
      }
      h2 {
        font-size: 20px;
        line-height: 28px;
        text-transform: none;
        color: $light-purple;
        margin: 32px 0;
        @media (min-width: $s) {
          font-size: 24px;
          line-height: 32px
        }
      }
      &__container {
        &--left {
          order: 2;
          @media (min-width: $s) {
            &.md\:w-2\/5 {
              width: 50%;
            }
          }
        }
        &--right {
          order: 1;
          @media (min-width: $s) {
            &.md\:w-3\/5 {
              width: 50%;
            }
          }
        }
      }
    }
  }

  .home-carousel {
    @media (min-width: 900px) and (max-width: 1280px) {
      max-width: 95%;
    }
  }

  .home-blockquote {
    .site-carousel {
      align-items: center;
    }
    .tns-outer {
      .tns-controls {

        [data-controls="next"],
        [data-controls="prev"] {
          width: 40px;
          height: 43px;
          opacity: 1;
        }

        [data-controls="prev"] {
          background: url(https://supadu-io.imgix.net/harpercollins-fancynancy/fancynancy-site/white-arrow-left.png?auto=format&w=40);
          background-repeat: no-repeat;
          background-position: left;
        }

        [data-controls="next"] {
          background: url(https://supadu-io.imgix.net/harpercollins-fancynancy/fancynancy-site/white-arrow-right.png?auto=format&w=40);
          background-repeat: no-repeat;
          background-position: right;
        }
      }
    }
  }
}