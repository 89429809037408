.site-carousel {
    margin-top: 30px;
    // main books collections carousel
    align-items: flex-start;

    .slider-nav__item {
        .component {
            &__link {
                margin-top: 3px;

                @media (min-width: $m) {
                    min-height: 50px;
                }
            }
        }
    }
}

//tns settings
.tns-outer {
    .tns-controls {
        [data-controls="next"],
        [data-controls="prev"] {
            width: 40px;
            height: 43px;
            opacity: 1;
        }

        [data-controls="prev"] {
            background: url(https://supadu-io.imgix.net/harpercollins-fancynancy/fancynancy-site/arrow-left.png?auto=format);
            background-repeat: no-repeat;
            background-position: left;
        }

        [data-controls="next"] {
            background: url(https://supadu-io.imgix.net/harpercollins-fancynancy/fancynancy-site/arrow-right.png?auto=format);
            background-repeat: no-repeat;
            background-position: right;
        }
    }
}

.slider-nav {
    &__item {

        &:hover,
        &:active {
            .component__link {
                .book-title {
                    background-color: $white;
                    border-color: $pale-pink;
                }
            }
        }

        .component {
            &__image {
                position: relative;
                z-index: 1;
                a {
                    transition: $animation-time all;
                    position: relative;
        
                    &:after {
                        content: '';
                        position: absolute;
                        top: -10%;
                        left: -10%;
                        bottom: -10%;
                        right: -10%;
                        z-index: -1;
                        border-radius: 50%;
                        transition: all 0.3s;
                    }
        
                    &:hover,
                    &:active {
                        &:after {
                            top: -3%;
                            left: -2%;
                            bottom: -2%;
                            right: -2%;
                            background-color: $pale-pink;
                        }
                    }
                }
            }

            &__link {
                a {
                    @extend %primary-font;
                    font-size: 22px;
                    line-height: 32px;
                    border: 3px solid transparent;
                    border-radius: 8px;
                    padding: 8px 5px;
                    color: $dark-pink;
                    margin-top: 4px;
                    @media (min-width: $m) {
                        padding: 8px 24px;
                    }
                    transition: $animation-time all;
                    &:hover,
                    &:active {
                        border-color: $pale-pink;
                    }
                }
            }
        }

        &.is-active {
            .component__link {
                .book-title {
                    background-color: $white;
                    border-color: $pale-pink;
                }
            }
        }

        @media (min-width: $s) {
            &:first-of-type {
                .component {
                    &__image {
                        justify-content: flex-end;

                        a {
                            margin-right: 48px;
                        }
                    }

                    &__link {
                        justify-content: flex-end;
                    }
                }
            }

            &:last-of-type {
                .component {
                    &__image {
                        justify-content: flex-start;

                        a {
                            margin-left: 48px;
                        }
                    }

                    &__link {
                        justify-content: flex-start;
                    }
                }
            }
        }
    }
}